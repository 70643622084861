<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px;" />
    </navigation>
    <transition name="extra_settings">
      <rg-widget
        v-if="showSettings"
        title="Erweiterte Einstellungen"
        style="z-index: 2; position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%); padding: 20px; width: 400px;"
      >
        <template #data>
          <div>
            <strong>RG Maschinen Titel</strong>
            <input
              v-model="currentRow.rg_title"
              type="text"
              style="width: 300px; margin-bottom: 20px; margin-right: 10px;"
              :placeholder="currentRow.serie ? currentRow.engine_type ? (currentRow.serie.name + '-' + currentRow.engine_type.name) : currentRow.serie.name : undefined"
            >
            <strong>RG Maschinen Slug</strong>
            <span
              v-if="slugError(currentRow)"
              style="color: red; margin-left: 5px;"
            >
              <fa
                class="fa-far"
                icon="exclamation-triangle"
                style="cursor: help;" 
                @mouseover="showSlugTooltip = true"
                @mouseleave="showSlugTooltip = false"
              />
              <div
                v-if="showSlugTooltip" 
                style="background-color: var(--input-background); color: red; text-align: center; padding: 5px; border-radius: 7px; position: absolute; z-index: 1; transform: translateY(-170%) translateX(65%); border: 2px solid red;"
              >
                Der Slug wird bereits verwendet
              </div>
            </span>
            <input
              v-model="currentRow.rg_slug"
              type="text"
              style="width: 300px; margin-bottom: 20px; margin-right: 10px; border: 0px solid red;"
              :style="{ 'border-width': currentRow.slugExists ? '1px' : '0px' }"
              :placeholder="generateSlugFromRow(currentRow)"
              @blur="checkSlug(currentRow, 0)"
              @input="checkSlug(currentRow)"
            >
            <div style="display: flex; align-items: center;">
              <rg-button
                type="is-primary"
                label="Schließen"
                style="margin-bottom: 10px;"
                @click="settings()"
              />
            </div>
          </div>
        </template>
      </rg-widget>

      <rg-widget
        v-if="showGranit"
        :title="clickedInput === 'brand' ? 'Granit Hersteller' : clickedInput == 'type' ? 'Granit Type' : clickedInput == 'serie' ? 'Granit Serie' : clickedInput == 'model' ? 'Granit Model' : 'ERROR'"
        class="w6 form"
        :style="{
          zIndex: 2,
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          padding: '20px',
          height: '490px',
          width: '440px'
        }"
      >
        <template #data>
          <div
            v-if="clickedInput != 'model'"
            style="margin-bottom: 10px;"
          >
            Ausgewählt bei Jaltest: {{ clickedInput == 'type' ? data.type.MachineType.titleDe : clickedInput == 'brand' ? data.brand.name : clickedInput == 'serie' ? data.series ? data.series.name : '/' : '/' }}
          </div>
          <div style="display: flex; align-items: center;">
            <input
              v-model="resultFilter.search"
              type="text"
              placeholder="Suche..."
              style="margin-right: 10px;"
              @input="dispatchSearch()"
              @keypress.enter="dispatchSearch(0)"
            >
            <rg-button
              type="is-primary"
              label="Schließen"
              style="margin-bottom: 10px;"
              @click="showGranit = false, resultFilter.search = '', mm1.data = undefined"
            />
          </div>
          <span
            v-if="resultFilter.loading"
            class="loader"
          />
          <div v-if="clickedInput === 'brand'">
            <div
              style="padding: 2px; margin-bottom: 4px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm1.data = undefined : undefined, mm2.data = undefined, mm2.page = 0, mm3.data = undefined, mm3.page = 0, data.mm1.category_1 = undefined, data.mm1.categoryid_1 = undefined, showGranit = false, resultFilter.search = '', data.mm2.category_2 = undefined, data.mm2.categoryid_2 = undefined, data.mm3.category_3 = undefined, data.mm3.categoryid_3 = undefined"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              None
            </div>
            <div
              v-for="brand in mm1.data"
              :key="brand.categoryid_1"
              style="padding: 2px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm1.data = undefined : undefined,mm2.data = undefined, mm2.page = 0, mm3.data = undefined, mm3.page = 0, data.mm1 = brand, showGranit = false, resultFilter.page = 0, resultFilter.search = '', data.mm2.category_2 = undefined, data.mm2.categoryid_2 = undefined, data.mm3.category_3 = undefined, data.mm3.categoryid_3 = undefined"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"      
            >
              {{ removeGranitText(brand.category_1) }} ({{ removeGranitText(brand.categoryid_1) }})
            </div>
          </div>
          <div v-if="clickedInput === 'type'">
            <div
              style="padding: 2px; margin-bottom: 4px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm2.data = undefined : undefined, mm3.data = undefined, mm3.page = 0, data.mm2.category_2 = undefined, data.mm2.categoryid_2 = undefined, showGranit = false, resultFilter.search = '', data.mm3.category_3 = undefined, data.mm3.categoryid_3 = undefined"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              None
            </div>
            <div
              v-for="granit_type in mm2.data"
              :key="granit_type.categoryid_2"
              style="padding: 2px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm2.data = undefined : undefined, mm3.data = undefined, mm3.page = 0, data.mm2 = granit_type, showGranit = false, resultFilter.page = 0, resultFilter.search = '', data.mm3.category_3 = undefined, data.mm3.categoryid_3 = undefined"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              {{ removeGranitText(granit_type.category_2) }} ({{ removeGranitText(granit_type.categoryid_2) }})
            </div>
          </div>
          <div v-if="clickedInput === 'serie'">
            <div
              style="padding: 2px; margin-bottom: 4px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm3.data = undefined : undefined, data.mm3.category_3 = undefined, data.mm3.categoryid_3 = undefined, showGranit = false, resultFilter.search = ''"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              None
            </div>
            <div
              v-for="granit_serie in mm3.data"
              :key="granit_serie.categoryid_3"
              style="padding: 2px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm3.data = undefined : undefined, data.mm3 = granit_serie, showGranit = false, resultFilter.page = 0, resultFilter.search = ''"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              {{ removeGranitText(granit_serie.category_3) }} ({{ removeGranitText(granit_serie.categoryid_3) }})
            </div>
          </div>

          <div v-if="clickedInput === 'model'">
            <input
              :value="clickedRow.granitId"
              type="text"
              placeholder="Granit ID"
              style="width: 100%; min-width: 200px;"
              @keypress.enter="clickedRow.granitId = $event.target.value, showGranit = false"
              @blur="clickedRow.granitId = $event.target.value"
            >
            <div
              style="padding: 2px; margin-bottom: 4px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm4.data = undefined : undefined, clickedRow.granitId = undefined, clickedRow.granitId = undefined, showGranit = false, resultFilter.search = ''"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              None
            </div>
            <div
              v-for="granit_model in mm4.data"
              :key="granit_model.categoryid_4"
              style="padding: 2px; background-color: var(--input-background); filter: brightness(85%); cursor: pointer; transition: background-color 0.3s ease;"
              @click="hoverEffect($event, '#2dad17'), resultFilter.search != '' ? mm4.data = undefined : undefined, clickedRow.granitId = removeGranitText(granit_model.categoryid_4), showGranit = false, resultFilter.page = 0, resultFilter.search = ''"
              @mouseover="hoverEffect($event, undefined, true)"
              @mouseout="hoverEffect($event, undefined, false)"
            >
              {{ removeGranitText(granit_model.category_4) }} ({{ removeGranitText(granit_model.categoryid_4) }})
            </div>
          </div>
          <div style="display: flex; justify-content: center; padding: 5px; margin-bottom: 5px; position: absolute; bottom: 0; left: 0; right: 0; z-index: 10;">
            <rg-button
              icon="angle-double-left"
              :disabled="(clickedInput == 'brand' ? mm1.page == 0 ? true : false : clickedInput == 'type' ? mm2.page == 0 ? true : false : clickedInput == 'serie'? mm3.page == 0 ? true : false : clickedInput == 'model' ? mm4.page == 0 ? true : false : false) || resultFilter.loading"

              style="padding-left: 3px; margin-left: 3px"
              @click="stride('first')"
            />
            <rg-button
              icon="angle-left"
              style="padding-left: 3px; margin-left: 3px"
              :disabled="(clickedInput == 'brand' ? mm1.page == 0 ? true : false : clickedInput == 'type' ? mm2.page == 0 ? true : false : clickedInput == 'serie'? mm3.page == 0 ? true : false : clickedInput == 'model' ? mm4.page == 0 ? true : false : false) || resultFilter.loading"
              @click="stride(-1)"
            />
            <rg-button
              icon="angle-right"
              style="padding-left: 5px; margin-left: 5px"
              :disabled="(clickedInput == 'brand' ? mm1.totalNum <= (mm1.page + 1) * 10 ? true : false : clickedInput == 'type' ? mm2.totalNum <= (mm2.page + 1) * 10 ? true : false : clickedInput == 'serie' ? mm3.totalNum <= (mm3.page + 1) * 10 ? true : false : clickedInput == 'model' ? mm4.totalNum <= (mm4.page + 1) * 10 ? true : false : false) || resultFilter.loading"
              @click="stride(1)"
            />
            <rg-button
              icon="angle-double-right"
              style="padding-left: 3px; margin-left: 3px"
              :disabled="(clickedInput == 'brand' ? mm1.totalNum <= (mm1.page + 1) * 10 ? true : false : clickedInput == 'type' ? mm2.totalNum <= (mm2.page + 1) * 10 ? true : false : clickedInput == 'serie' ? mm3.totalNum <= (mm3.page + 1) * 10 ? true : false : clickedInput == 'model' ? mm4.totalNum <= (mm4.page + 1) * 10 ? true : false : false) || resultFilter.loading"
              @click="stride('last')"
            />
          </div>
          <div
            style="display: flex; justify-content: space-between;  margin-left: 20px; margin-right: 20px;  margin-bottom: 20px; position: absolute; bottom: 0; left: 0; right: 0;"
          >
            <span v-if="clickedInput == 'brand'">{{ mm1.totalNum }} Ergebnisse</span>
            <span v-if="clickedInput == 'brand'">{{ mm1.page +1 }} von {{ Math.floor(mm1.totalNum / 10) +1 }}</span>
            <span v-if="clickedInput == 'type'">{{ mm2.totalNum }} Ergebnisse</span>
            <span v-if="clickedInput == 'type'">{{ mm2.page +1 }} von {{ Math.floor(mm2.totalNum / 10) +1 }}</span>
            <span v-if="clickedInput == 'serie'">{{ mm3.totalNum }} Ergebnisse</span>
            <span v-if="clickedInput == 'serie'">{{ mm3.page +1 }} von {{ Math.floor(mm3.totalNum / 10) +1 }}</span>
            <span v-if="clickedInput == 'model'">{{ mm4.totalNum }} Ergebnisse</span>
            <span v-if="clickedInput == 'model'">{{ mm4.page +1 }} von {{ Math.floor(mm4.totalNum / 10) +1 }}</span>
          </div>
        </template>
      </rg-widget>
    </transition>

    <main
      :style="{ 'pointer-events': (showSettings || showGranit) ? 'none' : 'auto', 'filter': (showSettings || showGranit) ? 'blur(5px)' : 'none', 'overflow': 'hidden' }"
    >
      <div style="position: fixed; border-bottom: 1px solid gray; display: flex; flex-direction: column; align-items: flex-start;  z-index: 1; width: 100%; justify-content: flex-start; background-color: var(--bg-primary); padding-top: 10px;">
        <div style="display: flex;">
          <div>
            <div style="display: flex; flex-direction: row;">
              <span>
                <h2>Machine Type</h2>
                <select
                  v-model="data.type"
                  class="rg-select"
                  style="width: 250px; margin-bottom: 5px; margin-right: 10px;"
                  @input="getJaltestBrand()"
                >
                  <option
                    v-for="option in types"
                    :key="option.machine_type_id"
                    :value="option"
                  >
                    {{ option.MachineType.titleDe }}
                  </option>
                </select>
              </span>
              <span>
                <h2>Maschinen Hersteller</h2>
                <select
                  v-model="data.brand"
                  :disabled="!data.type"
                  class="rg-select"
                  style="width: 250px; margin-bottom: 5px; margin-right: 10px;"
                  @input="getJaltestSerie()"
                >
                  <option
                    v-for="option in brand"
                    :key="option.id"
                    :value="option"
                  >
                    {{ `${option.name}` }}
                  </option>
                </select>
              </span>
              <span>
                <h2>Maschinen Serie</h2>
                <select
                  v-model="data.series"
                  :disabled="!data.brand"
                  class="rg-select"
                  style="width: 250px; margin-bottom: 5px; margin-right: 10px; opacity: 1;"
                  @input="getJaltestEngineType()"
                >
                  <option
                    v-for="option in series"
                    :key="option.id"
                    :value="option"
                  >
                    {{ `${option.name}` }}
                  </option>
                </select>
              </span>
            </div>
            <div style="display: flex;">
              <span>
                <h2>Granit Hersteller</h2>
                <input
                  :value="removeGranitText(data.mm1.category_1)"
                  :disabled="!data.brand"
                  type="text"
                  class="rg-select"
                  style="width: 250px; margin-bottom: 20px; margin-right: 10px;"
                  readonly
                  @click="clickedInput = 'brand', showGranit='true', mm1.data ? mm1.data.length != 0 ? undefined : getMM1_MODELS() : getMM1_MODELS()"
                >
              </span>
              <span>
                <h2>Granit Type</h2>
                <input
                  :value="removeGranitText(data.mm2.category_2)"
                  :disabled="!data.mm1.categoryid_1"
                  type="text"
                  class="rg-select"
                  style="width: 250px; margin-bottom: 20px; margin-right: 10px;"
                  readonly
                  @click="clickedInput = 'type', showGranit='true', mm2.data ? mm2.data.length != 0 ? undefined : getMM2_MODELS() : getMM2_MODELS()"
                >
              </span>
              <span>
                <h2>Granit Serie</h2>
                <input
                  :value="removeGranitText(data.mm3.category_3)"
                  :disabled="!data.mm2.categoryid_2"
                  type="text"
                  class="rg-select"
                  style="width: 250px; margin-bottom: 20px; margin-right: 10px;"
                  readonly
                  @click="clickedInput = 'serie', showGranit='true', mm3.data ? mm3.data.length != 0 ? undefined : getMM3_MODELS() : getMM3_MODELS()"
                >
              </span>
            </div>
          </div>
          <div style="margin-top: 3.6%;">
            <div>
              <rg-button
                :disabled="(!data.series || isExporting) || !data.mm2.categoryid_2"
                type="is-primary"
                label="Spalte hinzufügen"
                icon-left="plus"
                style="margin-top: 24px; position: absolute;"
                @click="addRow"
              />
              <div class="remove-button">
                <transition
                  name="remove_button"
                >
                  <rg-button
                    v-if="selectedRows.length != 0"
                    type="is-danger"
                    icon-left="trash"
                    :label="`${selectedRows.length}`"
                    style="border: 0px solid white;margin-top: 65px; margin-left: 58px; position: absolute;"
                    :disabled="isExporting"
                    @click="removeRow(-1)"
                  />
                </transition>
              </div>
            </div>
          
            <div style="display: flex; height: 10%; flex-direction: column; ">
              <rg-button
                :disabled="rows.length == 0 || isExporting"
                type="is-primary"
                :label="selectedRows.length > 0 ? `Maschine anlegen (${selectedRows.length})` : `Maschine anlegen (${rows.length})`"
                icon-left="save"
                style="margin-top: 5px; left: 75%;"
                @click="submit"
              />
              <rg-button
                disabled
                type="is-primary"
                :label="selectedRows.length > 0 ? `Maschine anlegen + Live (${selectedRows.length})` : `Maschine anlegen + Live (${rows.length})`"
                icon-left="save"
                style="margin-top: 5px; left: 75%;"
                @click="submit"
              />
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 170px; display: flex; justify-content: center; flex-direction: column;">
        <h1 style="display: flex; justify-content: center;">
          Jaltest Import
        </h1>
        <table>
          <thead class="column">
            <tr>
              <th style="width: 200px; border-color: orange;">
                FAHRZEUGTYP 
                <fa
                  class="fa-fw"
                  icon="tractor"
                />
              </th>
              <th style="width: 200px; border-color: orange;">
                HERSTELLER
                <fa
                  class="fa-fw"
                  icon="tractor"
                />
              </th>
              <th style="width: 200px; border-color: orange;">
                SERIE
                <fa
                  class="fa-fw"
                  icon="tractor"
                />
              </th>
              <th style="width: 200px; border-color: orange;">
                MODEL
                <fa
                  class="fa-fw"
                  icon="tractor"
                />
              </th>
              <th style="width: 200px; border-color:slateblue;">
                VARIANTE
                <fa-layers>
                  <fa
                    icon="circle"
                    transform="shrink-3 left-1.5 up-1.5"
                  />
                  <fa
                    icon="circle"
                    transform="right-5 down-5 shrink-10"
                  />
                </fa-layers>
              </th>
              <th style="width: 200px; border-color: red;">
                GRANITID
                <fa
                  class="fa-fw"
                  icon="store-alt"
                />
              </th>
            </tr>
          </thead>
          <transition-group
            tag="tbody"
            :name="isExporting ? 'export_highlight' : 'highlight'"
            class="row"
          >
            <tr
              v-for="item in rows"
              :key="item.id"
            >
              <td
                style="border-color: orange;"
              >
                {{ item.type.name }}
              </td>
              <td style="border-color: orange;">
                <input
                  @input="checkSlug(item)"
                  v-model="item.brand.name"
                  type="text"
                  style="width: 150px;"
                >
              </td>
              <td style="border-color: orange;">
                <input
                  @input="checkSlug(item)"
                  v-model="item.serie.name"
                  type="text"
                  style="width: 150px;"
                >
              </td>
              <td style="border-color: orange;">
                <input
                  @input="checkSlug(item)"
                  v-model="item.model_name"
                  type="text"
                  :placeholder="item.serie ? item.engine_type ? (item.serie.name + '-' + item.engine_type.name) : item.serie.name : undefined"
                  style="width: 230px;"
                >
              </td>
              <td
                style="border-color: slateblue;"
              >
                <span>
                  <select
                    v-model="item.engine_type"
                    :disabled="!item.engine_types"
                    class="rg-select"
                    style="width: 250px;"
                  >
                    <option
                      :value="undefined"
                    />
                    <option
                      v-for="option in item.engine_types"
                      :key="option.id"
                      :value="option"
                    >
                      {{ `${option.name}` }}
                    </option>
                  </select>
                </span>
              </td>
              <td style="border-color: red;">
                <input
                  v-model="item.granitId"
                  type="text"
                  style="width: 100%; min-width: 200px;"
                  readonly
                  @click="clickedRow = item, mm4.data ? mm4.data.length != 0 ? undefined : getMM4_MODELS() : getMM4_MODELS(), clickedInput='model', showGranit='true'"
                >
              </td>
              <td class="remove-button">
                <rg-button
                  type="is-danger"
                  icon="trash"
                  style="border: 0px solid white;"
                  :disabled="isExporting"
                  @click="removeRow(item.id), checkMachineSlugs()"
                />
              </td>
              <td style="border: none;">
                <rg-button
                  :disabled="isExporting"
                  type="is-primary"
                  icon-left="cog"
                  style="padding-right: 3.5px; border: 2px solid red;"
                  :style="{ 'border-width': slugError(item) ? '2px' : '0px' }"
                  @click="settings(item)"
                />
              </td>
              <td style="border: none;">
                <input
                  type="checkbox"
                  style="width: 20px; height: 20px; margin-left: 0;"
                  @click="selectRow(item.id)"
                >
              </td>
            </tr>
          </transition-group>
        </table>
      </div>
    </main>
  </div>
</template>
<script>
import superagent from "superagent";
import {altBackendUrl} from "@/constants.js";
import { ref,  onBeforeMount } from "@vue/composition-api";
import rgButton from '../../../components/elements/rg-button.vue';
import { es, generateSlug } from "@/helper.js";
import Vue from "vue";

export default {
  components: { 
    rgButton, 
    "rg-widget": () => import("@/components/elements/rg-widget")
  },
  setup(props, context) {
    onBeforeMount(() => {
      getMachineTypes()
      getMM1_MODELS()
    });
    let types = ref()
    let brand = ref()
    let series = ref()
    let engine_type = ref()

    let rg_machine_types = ref()

    let data = ref({
        "type": ref(),
        "brand": ref(),
        "series": ref(),
        "rg_title": ref(),
        "rg_slug": ref(),
        'mm1': ref({
          'category_1': undefined,
          'categoryid_1': undefined 
        }),
        'mm2': ref({
          'category_2': undefined,
          'categoryid_2': undefined 
        }),
        'mm3': ref({
          'category_3': undefined,
          'categoryid_3': undefined 
        }),
    })
    let rowid = 0
    let rows = ref([]);
    let isExporting = ref(false)
    let showSettings = ref(false)
    let currentRow = ref()
    let selectedRows = ref([])

    let resultFilter = ref({
      offset: 10,
      page: 0,
      search: "",
      limit: 10,
      loading: false,
      timeout: undefined
    });
    let showGranit = ref(false)
    let clickedInput = ref()
    let mm1 = ref({
      'data': undefined,
      'page': 0,
      'totalNum': 0
    })
    let mm2 = ref({
      'data': undefined,
      'page': 0,
      'totalNum': 0
    })
    let mm3 = ref({
      'data': undefined,
      'page': 0,
      'totalNum': 0
    })
    let mm4 = ref({
      'data': undefined,
      'page': 0,
      'totalNum': 0
    })
    let clickedRow = ref()
    let showSlugTooltip = ref(false)
    let has_error = false
    let errors = ref({
      db: [],
      es: []
    })

    const slugError = (row) => {
      if(row.slugExists || row.machineDuplicatedSlug) return true;
      return false;
    }

    function stride(amount){
      switch(clickedInput.value){
        case 'brand':
          if(amount == "first"){
            mm1.value.data = undefined
            mm1.value.page = 0
          } else if(amount == "last"){
            mm1.value.data = undefined
            mm1.value.page = Math.floor(mm1.value.totalNum / 10)
          } else {
            mm1.value.data = undefined
            mm1.value.page += amount
          }
          getMM1_MODELS()
          break
        case 'type':
          if(amount == "first"){
            mm2.value.data = undefined
            mm2.value.page = 0
          } else if(amount == "last"){
            mm2.value.data = undefined
            mm2.value.page = Math.floor(mm2.value.totalNum / 10)
          } else {
            mm2.value.data = undefined
            mm2.value.page += amount
          }
          getMM2_MODELS()
          break
        case 'serie':
          if(amount == "first"){
            mm3.value.data = undefined
            mm3.value.page = 0
          } else if(amount == "last"){
            mm3.value.data = undefined
            mm3.value.page = Math.floor(mm3.value.totalNum / 10)
          } else {
            mm3.value.data = undefined
            mm3.value.page += amount
          }
          getMM3_MODELS()
          break
        case 'model':
          if(amount == "first"){
            mm4.value.data = undefined
            mm4.value.page = 0
          } else if(amount == "last"){
            mm4.value.data = undefined
            mm4.value.page = Math.floor(mm4.value.totalNum / 10)
          } else {
            mm4.value.data = undefined
            mm4.value.page += amount
          }
          getMM4_MODELS()
          break
      }
    }


    const checkSlug = (row, ms = 700) => {
      resultFilter.value.timeout = setTimeout(() => {
        const gqQueryMachine = `
      query Machine($slug: String!) {
        Machine(slug: $slug) {
          id
          slug
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryMachine,
          variables: {
            slug: row.rg_slug ? generateSlug(row.rg_slug) : generateSlugFromRow(row)
          }
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(res => {
          row.slugExists = res.body?.data?.Machine?.id != undefined;
          checkMachineSlugs()
        })
        .catch(err => {
          console.log(err);
          Vue.$toast.open({
            duration: 60000,
            icon: "exclamation",
            type: "error",
            message:
              "Der Slug konnte nicht überprüft werden. Bitte versuche es später erneut."
          });
        });
      }, ms);
    };
    function checkSlugs() {
      for(let row of rows.value){
        checkSlug(row, 0)
      }
    }
    
  function checkMachineSlugs() {
    const machinesBySlug = {};

    rows.value.forEach(machine => {
      const slug = machine.rg_slug ? machine.rg_slug : generateSlugFromRow(machine);
      machine.machineDuplicatedSlug = false;
      
      machinesBySlug[slug] = machinesBySlug[slug] || [];
      machinesBySlug[slug].push(machine);
    });

    Object.values(machinesBySlug)
      .filter(machines => machines.length > 1)
      .flat()
      .forEach(machine => machine.machineDuplicatedSlug = true);
  }

    function getMachineTypes() {
        const gqQueryMachineTypes = `
          query JalMachineTypes {
            JaltestMachineTypes {
              jalTypeId
              machine_type_id
              MachineType {
                id
                titleDe
              }
            }
          }
          `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqQueryMachineTypes,
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            types.value = res.body?.data?.JaltestMachineTypes;
            console.log(types.value)
          });
      }

    function getJaltestBrand () {
      setTimeout(() => {
        data.value.brand = undefined
        data.value.series = undefined
        data.value.engine_type = undefined
        data.value.model = undefined
        data.value.granitId  = undefined

        engine_type.value = undefined
        brand.value = undefined

        const gqQueryJaltestMatching = `
        query getJaltestBrandQuery($vehicleTypeId: String) {
            JaltestCoverageBrands(vehicleTypeId: $vehicleTypeId)
        }
        `;

        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryJaltestMatching,
            variables: { vehicleTypeId: data.value.type.jalTypeId},
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then(({ body: MATCH }) => {
            brand.value = MATCH.data.JaltestCoverageBrands
      })
    }, 0);
  }
  

    function getJaltestSerie(){
      setTimeout(() => {
        data.value.series = undefined
        data.value.engine_type = undefined
        data.value.granitId = undefined
        series.value = undefined
        engine_type.value = undefined

        const gqQueryJaltestMatching = `
        query MyQuery($brandId: String) {
          JaltestCoverageModels(BrandId: $brandId)
        }
        `;
        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryJaltestMatching,
            variables: { brandId: data.value.brand.id },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then(({ body: MATCH }) => {
            series.value = MATCH.data.JaltestCoverageModels.body
      })}, 0);
    }

    function getJaltestEngineType(){
      setTimeout(() => {
        data.value.engine_type = undefined
        data.value.granitId = undefined
        data.value.rg_slug = undefined
        data.value.rg_title = undefined
        engine_type.value = undefined

        const gqQueryJaltestMatching = `
        query MyQuery($modelId: String) {
          JaltestCoverageEngineTypes(ModelId: $modelId)
        }
        `;
        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryJaltestMatching,
            variables: { modelId: data.value.series.id },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then(({ body: MATCH }) => {
            engine_type.value = MATCH.data.JaltestCoverageEngineTypes
            if(engine_type.value.length == 0){
              engine_type.value = undefined
            }

        })
      }, 0);
    }

    function getMM1_MODELS(){
      setTimeout(() => {
        resultFilter.value.loading = true

        const gqQueryGranitMatching = `
        query getMM1_MODELS($skip: Int! $search: String $limit: Int!) {
          MM1MODEL(skip: $skip, search: $search, limit: $limit) {
            category
            totalNum
          }
        }
        `;
        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryGranitMatching,
            variables: { skip: resultFilter.value.offset * mm1.value.page, search: resultFilter.value.search, limit: resultFilter.value.limit},
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then((res) => {
            mm1.value.totalNum = res?.body?.data?.MM1MODEL.totalNum
            let filteredArray = res?.body?.data?.MM1MODEL.category.filter(function(item) {
                return item.categoryid_1 !== undefined && item.categoryid_1 !== '' && item.categoryid_1 !== undefined;
            });
            mm1.value.data = filteredArray
            resultFilter.value.loading = false
        })
      }, 0);
    }

    function getMM2_MODELS(){
      setTimeout(() => {

        resultFilter.value.loading = true

        const gqQueryGranitMatching = `
        query getMM2_MODELS($mm1_id: String! $skip: Int! $search: String $limit: Int!) {
          MM2MODEL(mm1_id: $mm1_id, skip: $skip, search: $search, limit: $limit) {
            category
            totalNum
          }
        }
        `;
        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryGranitMatching,
            variables: { mm1_id: data.value.mm1.categoryid_1, skip: resultFilter.value.offset * mm2.value.page, search: resultFilter.value.search, limit: resultFilter.value.limit },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then((res) => {
            mm2.value.totalNum = res?.body?.data?.MM2MODEL.totalNum

            let filteredArray = res?.body?.data?.MM2MODEL.category.filter(function(item) {
                return item.categoryid_2 !== undefined && item.categoryid_2 !== '' && item.categoryid_2 !== undefined;
            });
            mm2.value.data = filteredArray
            resultFilter.value.loading = false
        })
      }, 0);
    }

    function getMM3_MODELS(){
      setTimeout(() => {
        resultFilter.value.loading = true
        const gqQueryGranitMatching = `
        query getMM3_MODELS($mm2_id: String! $skip: Int! $search: String $limit: Int!) {
          MM3MODEL(mm2_id: $mm2_id, skip: $skip, search: $search, limit: $limit) {
            category
            totalNum
          }
        }
        `;
        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryGranitMatching,
            variables: { mm2_id: data.value.mm2.categoryid_2, skip: resultFilter.value.offset * mm3.value.page, search: resultFilter.value.search, limit: resultFilter.value.limit },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then((res) => {
            mm3.value.totalNum = res?.body?.data?.MM3MODEL.totalNum
            let filteredArray = res?.body?.data?.MM3MODEL.category.filter(function(item) {
                return item.categoryid_3 !== undefined && item.categoryid_3 !== '' && item.categoryid_3 !== undefined;
            });
            mm3.value.data = filteredArray
            resultFilter.value.loading = false
        })
      }, 0);
    }

    function getMM4_MODELS(){
      setTimeout(() => {
        resultFilter.value.loading = true
        const mm2_value = clickedRow.value.mm3 ? undefined : clickedRow.value.mm2
        const mm3_value = clickedRow.value.mm3 ? clickedRow.value.mm3 : undefined

        const gqQueryGranitMatching = `
        query getMM4_MODELS($mm2_id: String $mm3_id: String $skip: Int! $search: String $limit: Int!) {
          MM4MODEL(mm2_id: $mm2_id, mm3_id: $mm3_id, skip: $skip, search: $search, limit: $limit) {
            category
            totalNum
          }
        }
        `;
        superagent
        .post(altBackendUrl)
        .send({
            query: gqQueryGranitMatching,
            variables: { mm2_id: mm2_value, mm3_id: mm3_value, skip: resultFilter.value.offset * mm4.value.page, search: resultFilter.value.search, limit: resultFilter.value.limit },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then((res) => {
            mm4.value.totalNum = res?.body?.data?.MM4MODEL.totalNum
            let filteredArray = res?.body?.data?.MM4MODEL.category.filter(function(item) {
                return item.categoryid_4 !== undefined && item.categoryid_4 !== '' && item.categoryid_4 !== undefined;
            });
            mm4.value.data = filteredArray
            resultFilter.value.loading = false
        })
      }, 0);
    }

    function settings(item){
      if (showSettings.value){
        showSettings.value = false
        for(var row of rows.value){
          if(row.id == currentRow.value.id){
            row = currentRow
            break
            }
          }
      } else {
        showSettings.value = true
        currentRow.value = item
      }
    }

    function addRow(){
      const payload = {
          'id': rowid,
          'rg_title': undefined,
          'rg_slug': undefined,
          'rg_type': data.value.type.MachineType.id,
          'engine_types': engine_type.value,
          'granitId': undefined,
          'model_name': data.value.model_name,
          'mm2': data.value.mm2.categoryid_2,
          'mm3': data.value.mm3.categoryid_3,
          'mm4': undefined,
          'type': {
            'id': data?.value?.type?.jal_type_id || undefined,
            'name': data?.value?.type?.MachineType?.titleDe,
            'other': data.value.type
          },
          'brand': {
            'id':  data?.value?.brand.id || undefined,
            'name': data.value.brand.name,
            'other': data.value.brand
          },
          'serie': {
            'id': data?.value?.series?.id || undefined,
            'name': data.value.series.name.replace("Series", "").replace(/\s+/g, " ").trim(),
            'other': data.value.series
          },
          'engine_type': undefined,
          'slugExists': false,
          'machineDuplicatedSlug': false,
      };
      checkSlug(payload, 0)
      rowid++
      rows.value.push(payload);
    }

    function removeRow(id){
      if(id == -1){
        let newRows = [];
        for(const row of rows.value){
          if(selectedRows.value.includes(row.id)){
            var selected_row_ind = selectedRows.value.indexOf(row.id)
            selectedRows.value.splice(selected_row_ind, 1)
          } else {
            newRows.push(row);
          }
        }
        rows.value = newRows;
      }

      for(const row of rows.value){
        if(row.id == id){
          if(selectedRows.value.includes(id)){
            var selected_row_index = selectedRows.value.indexOf(id)
            selectedRows.value.splice(selected_row_index, 1)
          }
          var row_index = rows.value.indexOf(row)
          rows.value.splice(row_index, 1)
        }
      }
    }

    const importRow = async () => {
        var row = undefined
        var row_index = undefined
        var selected_row_index

        if (has_error){
          return
        }

        if(selectedRows.value.length > 0) {
          for(const i of rows.value.slice().reverse()){
            selected_row_index = selectedRows.value.indexOf(i.id)
            if(selected_row_index != -1){
              row_index = rows.value.indexOf(i)
              row = rows.value[row_index]
              break
            }
          }
        } else {
          row = rows.value.slice(-1)[0];
        }
        if (row.slugExists){
          Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Konnte Maschine nicht anlegen, da der Slug bereits vorhanden ist."
            });
          has_error = true
          return
        }
        let payload = {
          authorid: parseInt(context.root.$store.state.user.id),
          title: row.rg_title ? row.rg_title : (row.engine_type ? (row.serie.name + "-" + row.engine_type.name) : row.serie.name),
          slug: row.rg_slug ? generateSlug(row.rg_slug) : generateSlugFromRow(row),
          manufacturer: row.brand.name,
          series: row.serie.name,
          model: row.model_name ?
            row.model_name 
            : row.serie.name ?
              row.engine_type?.name ?
                row.serie.name + '-' + row.engine_type?.name 
                : row.serie.name 
              : undefined,
          type: row.rg_type
        }

        const gqAddMachineMutation = `
        mutation addMachine($id: Int!, $slug: String, $title: String, $manufacturer: String, $series: String, $model: String $type: Int, $authorId: Int, $granitId: Int) {
          Machine(id: $id, slug: $slug, title: $title, manufacturer: $manufacturer, series: $series, model: $model, type: $type, authorid: $authorId, granitid: $granitId) {
            id
            slug
            title
            Type {
              id
            }
            manufacturer
            series
            model
            granitId
            Jaltest {
              id
            }
          }
        }
        `;
        await superagent
        .post(altBackendUrl)
        .send({
            query: gqAddMachineMutation,
            variables: { 
              id: -1, 
              slug: payload.slug, 
              title: payload.title,
              manufacturer: payload.manufacturer, 
              series: payload.series,
              model: payload.model,
              type: payload.type, 
              authorId: payload.authorid,
              granitId: parseInt(row.granitId) 
            },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then(async (resMachine) => {
          const jal_id = resMachine?.body?.data?.Machine?.Jaltest?.id
          if (jal_id == undefined){
            Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Ein Fehler ist beim anlegen der Maschine aufgetreten."
            });
            has_error = true
            return
            }
          const JaltestPayload = {
            id: parseInt(jal_id),
            vehicleTypeId: row.type.id,
            vehicleTypeTitle: row.type.name,
            brandId: row.brand.id,
            brandTitle: row.brand.name,
            modelId: row.serie.id,
            modelTitle: row.model_name ? row.model_name : (row.engine_type ? (row.serie.name + "-" + row.engine_type.name) : row.serie.name),
            engineId: row?.engine_type?.id,
            engineTitle: row?.engine_type?.name
          }
          console.table(JaltestPayload)

          const gqAddJaltestModelMutation = `
          mutation addJaltestModel($id: Int, $vehicleTypeId: String, $vehicleTypeTitle: String, $brandId: String, $brandTitle: String, $modelId: String, $modelTitle: String, $engineId: String, $engineTitle: String) {
            JaltestModel(id: $id, vehicle_type_id: $vehicleTypeId, vehicle_type_title: $vehicleTypeTitle, brand_id: $brandId, brand_title: $brandTitle, model_id: $modelId,  model_title: $modelTitle,  engine_id: $engineId,  engine_title: $engineTitle) {
              id
            }
          }
          `;
        await superagent
        .post(altBackendUrl)
        .send({
            query: gqAddJaltestModelMutation,
            variables: { 
              id: JaltestPayload.id,
              vehicleTypeId: JaltestPayload.vehicleTypeId,
              vehicleTypeTitle: JaltestPayload.vehicleTypeTitle,
              brandId: JaltestPayload.brandId,
              brandTitle: JaltestPayload.brandTitle,
              modelId: JaltestPayload.modelId,
              modelTitle: JaltestPayload.modelTitle,
              engineId: JaltestPayload.engineId,
              engineTitle: JaltestPayload.engineTitle,
            },
        })
        .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
        .then(async () => {
          if(selectedRows.value.length > 0) {
            selectedRows.value.splice(selected_row_index, 1)
            rows.value.splice(row_index, 1)[0]
          } else {
            rows.value.pop()
          }
          // MARK: Elastic Search Import
          console.log(resMachine?.body?.data?.Machine)
          // Building ES Paylod
          const espayload = {
            id: resMachine?.body?.data?.Machine?.id,
            isparent: false,
            slug: resMachine?.body?.data?.Machine?.slug,
            status: "disabled",
            title: resMachine?.body?.data?.Machine?.title,
            manufacturer: resMachine?.body?.data?.Machine?.manufacturer,
            type: resMachine?.body?.data?.Machine?.Type?.id,
            series: resMachine?.body?.data?.Machine?.series,
            model: resMachine?.body?.data?.Machine?.model,
            granitid: resMachine?.body?.data?.Machine?.granitId,
            hastechdata: false,
            hasserviceplan: false,
            hasparts: resMachine?.body?.data?.Machine?.granitid ? true : false,
          };
          if(!espayload.id || !espayload.slug || !espayload.title){
            console.log("No ID found in ES Payload")
             Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Die Maschine konnte nicht in die Suche importiert werden, da keine ID, Slug oder Titel übergeben wurde"
            });
            return
          }
          // Handling of ES in dev mode
          if (process.env.NODE_ENV === "development") { 
            console.table(espayload)
             Vue.$toast.open({
              duration: 10000,
              icon: "info",
              type: "info",
              message:
                "Ein Import in die ElasticSearch Suche ist nicht möglich, da die Funktion in der Entwicklungsumgebung nicht verfügbar ist."
            });
            return
          }

          // Import into ElasticSearch

            superagent
              .post(
                es +
                  "/repguide_machines/_create/" +
                  espayload.id +
                  "?routing=" +
                  espayload.id.toString()
              )
              .set(
                "Authorization",
                "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
              )
              .send(espayload)
              .then(() => {
                Vue.$toast.open({
                  icon: "check",
                  message:
                    "Maschine erfolgreich in die Suche importiert."
                });
                return
              })
              .catch(error => {
                console.trace(error);
                errors.value.es.push(...espayload.id, error);
                return
              });

          if (errors.value.es.length > 0){
            Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Ein Fehler ist beim Importieren der Maschine in die Suche aufgetreten."
            });
            return
          }

          const saveTabMutation = `
            mutation Mutation($id: Int!, $tabType: Int, $machineId: Int) {
              Tab(
                id: $id,
                tab_type: $tabType,
                machineid: $machineId,
                tab_active: true
              ) {
                id
              }
            }
          `;
          const TAB_PAYLOAD = {
            id: -1,
            machineId: resMachine?.body?.data?.Machine?.id,
            tabTypeId: 9
          }
          await superagent
          .post(altBackendUrl)
          .send({
              query: saveTabMutation,
              variables: { 
                id: TAB_PAYLOAD.id,
                machineId: TAB_PAYLOAD.machineId,
                tabType: TAB_PAYLOAD.tabTypeId
              },
          })
          .set("Authorization", `Bearer ${context.root.$store.state.user.apiToken}`)
          .catch(err => {
            console.log(err);
            Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Ein Fehler ist beim aktivieren vom Jaltest Tab aufgetreten.."
            });
            has_error = true
            return
          });

      }).catch(err => {
          console.log(err);
          Vue.$toast.open({
            duration: 10000,
            icon: "exclamation",
            type: "error",
            message:
              "Ein Fehler ist beim anlegen der Maschine aufgetreten."
          });
          has_error = true
          return
        });
    }).catch(err => {
          console.log(err);
          Vue.$toast.open({
            duration: 10000,
            icon: "exclamation",
            type: "error",
            message:
              "Ein Fehler ist beim anlegen der Maschine aufgetreten."
          });
          has_error = true
          return
        });
  }

  async function submit() {
    isExporting.value = true;
    if (selectedRows.value.length > 0){
        while(selectedRows.value.length > 0 && !has_error) {
            await importRow();
        }
    } else {
        while(rows.value.length > 0 && !has_error) {
            await importRow();
        }
        rowid = 0
    }
    checkSlugs()
    isExporting.value = false;
    has_error = false
  }


  function selectRow(id){
    var selected_row_index = selectedRows.value.indexOf(id)
    if(selected_row_index != -1){
      selectedRows.value.splice(selected_row_index, 1)
      return
    }
    selectedRows.value.push(id)
  }

  const dispatchSearch = (ms = 700) => {
      clearTimeout(resultFilter.value.timeout);

      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.page = 0;
        switch(clickedInput.value){
          case 'brand':
            mm1.value.data = undefined
            mm1.value.page = 0
            getMM1_MODELS()
            break;
          case 'type':
            mm2.value.data = undefined
            mm2.value.page = 0
            getMM2_MODELS()
            break;
          case 'serie':
            mm3.value.data = undefined
            mm3.value.page = 0
            getMM3_MODELS()
            break;
          case 'model':
            mm4.value.data = undefined
            mm4.value.page = 0
            getMM4_MODELS()
            break
        }
      }, ms);
    };


  function removeGranitText(str){
    if(str == undefined){
      return str
    }
    let lastIndex = str.lastIndexOf('_');
    if (lastIndex !== -1) {
        return str.substring(lastIndex + 1).replace(">", "");
    } else {
        return str;
    }
  }

  const generateSlugFromRow = (row) => {
    const fragments = [
      row?.brand?.name, 
      row?.serie?.name, 
      row?.model_name
    ].filter(Boolean);

    return generateSlug(fragments.join("-"));
  };

  function hoverEffect(event, color, makeBrigther = undefined) {
    if(color != undefined){
      event.target.style.backgroundColor = color;
      return
    }
    if(makeBrigther){
      event.target.style.filter = "brightness(95%)";
      return
    } else {
      event.target.style.filter = "brightness(85%)";
      return
    }
  }

  
    return {
        types,
        brand,
        series,
        engine_type,
        data,
        getJaltestBrand,
        getJaltestSerie,
        getJaltestEngineType,
        rows,
        addRow,
        removeRow,
        submit,
        isExporting,
        showSettings,
        settings,
        rg_machine_types,
        generateSlug,
        currentRow,
        selectedRows,
        selectRow,
        removeGranitText,
        mm1,
        mm2,
        mm3,
        mm4,
        showGranit,
        clickedInput,
        resultFilter,
        hoverEffect,
        dispatchSearch,
        clickedRow,
        getMM1_MODELS,
        getMM2_MODELS,
        getMM3_MODELS,
        getMM4_MODELS,
        stride,
        checkSlug,
        showSlugTooltip,
        generateSlugFromRow,
        slugError,
        checkMachineSlugs
    };
  },
};
</script>
<style scoped>
table{
  border-collapse: collapse;
}

main {
  transition: 0.2s ease-in-out;
  padding-top: 0.1px;
}


.column th{
  border: 2px solid gray;
  padding: 5px;
  text-align: left;
}

.row td{
  border: 2px solid gray;
  padding: 5px;
  text-align: left;
  height: 40px;
}
.column th, .row td {
  max-width: 19%;
  width: 19%;
}

.row .remove-button {
  margin: 0;
  border: none;
  transition: all 0.5s ease-in-out;
}
.extra_settings-enter-active, .extra_settings-leave-active {
  transition: all 0.25s ease-in-out;
}
.extra_settings-enter, .extra_settings-leave-to {
  opacity: 0;
}

.highlight-enter-active {
    animation: highlight 0.5s ease-in-out;
}
@keyframes highlight {
    from { opacity: 0; background-color: green; transform: translateY(30px); border: none;}
}

.highlight-leave-active {
  transition: all 0.5s ease-in-out;
  transform: translateX(30px);
  background-color: red;
  opacity: 0;
}
.export_highlight-leave-active {
  transition: all 0.5s ease-in-out;
  transform: translateX(30px);
  background-color: #8feb34;
  opacity: 0;
}

.remove_button-enter-active {
  animation: bounce-in 0.5s ease;
}
.remove_button-leave-active {
  animation: bounce-out 0.5s ease;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% { transform: scale(.9); }
  100% { transform: scale(1); }
}

@keyframes bounce-out {
  0% { transform: scale(1); }
  25% { transform: scale(.95); }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(.3);
  } 
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.remove-button button:hover {
  transition: 0.2s;
  animation: rotate 0.2s infinite;
  background-color: darkred;
  z-index: 0;
}


.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  border-top: 4px solid var(--input-background);
  filter: brightness(90%);
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation-loader 1s linear infinite;
  z-index: 10;
  bottom: 35%;
  right: 43%;
}

@keyframes rotation-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
